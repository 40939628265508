@import "./bootstrap.min.css";
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;500&display=swap');

@font-face {
    font-family: "Poppins Medium";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/Poppins-Medium.ttf");
}

/*@font-face {*/
/*    font-family: "Poppins Regular";*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    src: url("./assets/fonts/Poppins-Regular.ttf");*/
/*}*/

/*@font-face {*/
/*    font-family: "Poppins SemiBold";*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    src: url("./assets/fonts/Poppins-SemiBold.ttf");*/
/*}*/

/*@font-face {*/
/*    font-family: "Poppins Bold";*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    src: url("./assets/fonts/Poppins-Bold.ttf");*/
/*}*/

@font-face {
    font-family: "Poppins ExtraBold";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/Poppins-ExtraBold.ttf");
}

@font-face {
    font-family: "Poppins Light";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/Poppins-Light.ttf");
}

/*@font-face {*/
/*    font-family: "Poppins Thin";*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    src: url("./assets/fonts/Poppins-Thin.ttf");*/
/*}*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    outline: 0;
    border: 0;
    list-style: none;
    letter-spacing: 1px;
}

* {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

:root {
    --color-bg: #f3f3f3;
    --dark-font-color:#1f1f38;
    --color-bg-variant: #717185;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
    /*--font-primary: "Poppins Medium";*/
    --font-primary: 'Cairo', sans-serif;
    --transition: all 400ms ease-in;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;

}

body {
    font-family: var(--font-primary) !important;
    background-color: var(--color-bg) !important;
    color: var(--dark-font-color) !important;
    line-height: 1.7 !important;

}

._backgroundColor {
    background: var(--color-primary);
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 500 !important;
    text-transform: capitalize !important;
    margin-bottom: 0 !important;
}

section {
    margin-top: 5rem !important;
}

.finger-down {
    transform: rotate(180deg);
}

section > h2, section > h5 {
    text-align: center !important;
    /*color: var(--color-light) !important;*/
}

.sectionStyleH5 {
    text-align: center !important;
    /*color: var(--color-light) !important;*/
}

.sectionStyleH2 {
    margin-bottom: 1rem !important;
    /*color: var(--color-primary) !important;*/
}

.margin-t {
    margin-top: 2rem;
}

.line {
    width: 80%;
    height: 2px;
    background-color: var(--color-white);
    z-index: 5;
    margin: 3rem auto;
}

section > h2 {
    margin-bottom: 1rem !important;
    /*color: var(--color-primary) !important;*/
}

.text-light {
    color: var(--color-light) !important;
}

a {
    color: var(--color-primary) !important;
    transition: var(--transition) !important;

}

.main-text-color {
    color: var(--color-primary) !important;
}

.secondary-text-color {
    color: var(--dark-font-color) !important;
}

a:hover {
    /*color: var(--color-white) !important;*/
}

small {
    color: var(--color-white) !important;
    letter-spacing: 1px;
    line-height: 1.2rem;
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--dark-font-color);
    border-color: transparent;
}

.btn-primary {
    background: var(--dark-font-color) !important;
    color: var(--color-bg) !important;
}

.btn-primary:disabled {
    background-color: var(--color-bg-variant) !important;;
    color: var(--color-light) !important;
    outline: unset !important;
    border-color: unset !important;
    cursor: unset !important;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

.capitalize {
    text-transform: capitalize;
}

.text-gray {
    color: var(--dark-font-color);
}

.emergency{
    background: rgb(255,12,21);
    background: -moz-radial-gradient(circle, rgba(255,12,21,0.5998774509803921) 0%, rgba(255,0,0,0) 54%);
    background: -webkit-radial-gradient(circle, rgba(255,12,21,0.5998774509803921) 0%, rgba(255,0,0,0) 54%);
    background: radial-gradient(circle, rgba(26, 0, 108, 0.6) 0%, rgba(255,0,0,0) 54%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0c15",endColorstr="#ff0000",GradientType=1);
}


/*_____________________MEDIA QUERIES (MEDIUM DEVICES)__________________________________*/
@media only screen and(max-width: 1024px ) {
    .container {
        width: var(--container-width-md) !important;
    }

    section {
        margin-top: 5rem;
    }
}

/*_____________________MEDIA QUERIES (SMALL DEVICES)__________________________________*/
@media only screen and(max-width: 600px ) {
    section {
        display: none !important;
        background-color: white !important;
        margin-top: 2rem !important;
    }

    .container {
        width: var(--container-width-sm);
        display: none !important;
    }

    section > h2 {
        margin-top: 2rem;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.linearProgressContainer {
    position: fixed;
    width: 100%;
    /*top: 0;*/
    height: 100%;
    z-index: 1500;
}

span {
    padding: 0;
    margin: 0;
}

.c-pointer {
    cursor: pointer;
}

.animateUp {
    animation: fadeOutOpacity 1s ease-in forwards;
    opacity: 0;
}

#head {
    animation: fadeOutHead 5s ease infinite;
    transform-origin: center;
    /*transform-box: content-box;*/
    /*transform-box: border-box;*/
    /*transform: rotateZ(1.2deg);*/
    transform-box: fill-box;
    /*transform-box: stroke-box;*/

}

.texts-boxs:nth-child(odd) {
    transform-origin: center;
    /*animation: typing 1s infinite linear;*/
    animation: fadeOut 600ms infinite linear;
    transform-box: fill-box;

}

.texts-boxs:nth-child(even) {
    animation: fadeOut 600ms infinite linear;
    transform-origin: center;
    animation-delay: 1s;
    transform-box: fill-box;

}

@keyframes fadeOut {
    20% {
        transform: translateY(0);
    }
    55% {

        /*transform: translateY(200%);*/
    }
    100% {
        transform: translateY(10px);
    }
}
@keyframes fadeOutOpacity {
    20% {
        transform: translateY(0);
    }
    55% {

        /*transform: translateY(200%);*/
    }
    100% {
        /*transform: translateY(10px);*/
        opacity: 1;
    }
}
@keyframes fadeOutHead {
    0% {
        transform: rotateZ(0);
    }
    55% {
        transform: rotateZ(10deg)
    }
    100% {
        transform: rotateZ(0);
    }
}


@keyframes typing {
    0% {
        /*display: none;*/
        transform: translateX(0);
    }
    100% {
        /*display: unset;*/
        transform: translateX(50px);
    }
}
