.formInput{
  width: 100%;
  padding: 2rem;
  background-color: transparent;
  border: 1px solid var(--color-bg-variant);
  border-radius: 2rem;
  margin: 1rem;
  resize: none;
  font-size: 1rem;
  color: var(--color-white);
}
