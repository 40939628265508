.PreviousJobCardWrapper{
  background-color: var(--color-bg-variant);
  border-radius: 2rem;
  text-align: center;
  padding-top: 2rem;
  width: 100%;
}
.PreviousJobCardContent{
  display: flex;
  //width: max-content;
  flex-wrap: wrap;
  //max-width: 300px;
  article{
    flex: 50%;
  }
}
.PreviousJobCardDetails{
  width: max-content;

  padding: 1.3rem 2rem;
  justify-content: flex-start;
  text-align: start;
  .titleIcon{
    color: var(--color-primary);
  }
  .titleContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    font-size: 1.3rem;
  }
  .detailsContainer{
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: nowrap;
    padding: 0.2rem 0;
    //justify-content: center;
    gap: 0.5rem;
    //font-size: 1.3rem;
  }
}

@media only screen and(max-width:1024px ) {
  .PreviousJobCardWrapper{
    padding-top: 1rem;
    article{
      flex: 100%;
    }

  }
  .PreviousJobCardDetails{
    padding: 1rem;
    .titleContainer{
      display: flex;
      gap: 0.2rem;
      font-size: 1rem;
    }
  }

}
/*_____________________MEDIA QUERIES (SMALL DEVICES)__________________________________*/
@media only screen and(max-width:600px ) {

}
