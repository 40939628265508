.aboutContainer {
  display: grid;
  grid-template-columns: 35% 50%;
  justify-content: space-between;
  align-items: stretch;

}

.aboutImg {
  width: unset;
  aspect-ratio: 1/1;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  border-radius: unset;
  overflow: hidden;
  background-color: unset;
  height: 100%;
}

.aboutMe {
  //width: 100%;
  //aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
  overflow: hidden;

  img {
    border-radius: 2rem;
    object-fit: cover;
    transition: all 400ms ease-in-out;
    transform: scale(1.8);
  }

}

.aboutContent {
  overflow: hidden;

  p {
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
    //color: ;
  }
}

.cardWrapper {
  display: flex;
  width: 100%;
  gap: 0.7rem;
  overflow-x: scroll;
  scroll-behavior: auto;

}

.typing-animation:after {
  content: "";
  width: 100%;
  position: absolute;
  height: 100%;
  top:0;
  //right: 0;
  border-left: 1px solid var(--color-primary);
  background-color: var(--color-bg);
  //transform: translateX(-50px);
  animation: typing 8s steps(50) infinite;
}

@keyframes typing {
  40%, 50% {
    transform: translateX(-100%)
  }
  80%,90%{
    transform: translateX(0)
  }
}

/*_____________________MEDIA QUERIES (MEDIUM DEVICES)__________________________________*/
@media screen and(max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  .aboutContainer {
    grid-template-columns: 1fr;

    p {

    }
  }
  .aboutMe {
    width: 50%;
    margin: 2rem auto 4rem;
  }

}

/*_____________________MEDIA QUERIES (SMALL DEVICES)__________________________________*/
@media screen and(max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
  .aboutMe {
    width: 80%;
    //margin: 2rem auto 4rem;
  }


}
