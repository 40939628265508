nav{
  background-color: rgba(0,0,0,0.4);
  backdrop-filter: blur(15px);
  width: max-content;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  padding: 0.7rem 1.7rem;
  z-index: 1000;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1rem;
  a{
    background: transparent;
    padding: 1rem;
    border-radius: 40%;
    color: var(--color-light);
    font-size: 1rem;
    &:hover{
      background: rgba(0,0,0,0.4);
    }
    &.active{
      background: var(--color-bg);
      color: var(--color-white);

    }
  }
}
