
.infoFormWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.textField {
  //color: white !important;
  outline: white !important;
  border-color: white !important;
}

.infoFormItems {
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  gap: 1rem;
  margin-top: 1rem;

  .infoFormItem {
    //min-width: max-content;
    position: relative;
    gap: 0.3rem;
    display: flex;
  }
}

.actionButton {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;

  button {
    padding: 0.2rem;
    width: 35px;
    height: 20px;
  }
}
.delete {
  color: red;
  position: absolute;
  top: 0;
  right: 0;
  //font-size: 2rem;
  padding: 0.2rem;

  &:hover {
    background-color: white;
    cursor: pointer;
  }
}

