.footerContainer {
  width: 100%;
  //height: 500px;
  background-color: var(--color-bg-variant);
  padding: 2rem 0;
  position: relative;
  margin-top: 2rem;

}

.permalinks {
  display: flex;
  //flex-direction: column;
  width: 100%;
  color: black;
  //justify-content: sp;
  gap: 1rem;

  li, a {
    color: black;
  }
}

.footerCopyright {
  //position: absolute;
  //text-align: center;
  bottom: 0;
  //margin: 0 auto;
  width: 100%;
  text-align: center;
  //margin-bottom: 5rem;
}

.footerItems {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
}
