.projectCardWrapper {
  background-color: var(--color-bg-variant);
  //background-color: var(--color-bg-variant);
  border-radius: 2rem;
  text-align: center;
  //padding-top: 2rem;
  max-width: 400px;
  display: flex;
  flex-flow: column nowrap;
  //gap: 0.5rem;
  overflow: hidden;

  h3 {
    //margin-bottom: 3rem;
    color: var(--color-primary);
  }
}

.hover-effect:hover {
  transform: scale(1.5);
  transition: all 1s ease-in-out;

}

.hover-effect {
  transition: all 1s ease-in-out;

}

.imagContainer {
  width: 100%;
  position: relative;
  min-height: 200px;


  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--color-primary);
    margin-top: 20px;
    z-index: 0;
    top: 0;
    box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.2)
  }

  img {
    width: 100%;
    z-index: 1;
    position: sticky;
    object-fit: cover;
  }
}

.projectCardContent {
  display: flex;
  //width: max-content;
  flex-wrap: wrap;
  //max-width: 300px;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: start;

  article {
    flex: 50%;
  }
}

.buttons {
  width: 100%;
  //background-color: var(--color-primary);
  padding: 0.5rem 0;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.content {
  --max-lines: 2;
  position: relative;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  overflow: hidden !important;
  -webkit-line-clamp: var(--max-lines) !important;



}
.active{
  -webkit-box-orient: unset !important;
}
.projectCardContent:has(.expand-button:checked) .content {
  -webkit-box-orient: unset !important;
}

//.projectCardContent:has(.price) .content{
//  -webkit-box-orient: unset !important;
//
//}

.expand-button {
  appearance: none;
  display: block;
  width: 100%;
}

.expand-button:before {
  content: 'read more';
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
}

.expand-button:checked:before {
  content: 'read less';
}


@media only screen and(max-width: 1024px) {
  .experienceWrapper {
    //padding-top: 1rem;
  }
  .experienceDetails {
    padding: 2rem;

    .titleContainer {
      display: flex;
      gap: 0.2rem;
      font-size: 1rem;
    }
  }

}

/*_____________________MEDIA QUERIES (SMALL DEVICES)__________________________________*/
@media only screen and(max-width: 600px) {

}
