.statisticsContainer{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;
}
.StatisticsSection{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1px;
  margin-bottom: 1rem;
}
.detailsContainer{
  display: flex;

  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  //justify-content: space-between;
}
.detailsContainer .statistics{
  flex-wrap: nowrap;
  overflow-x: scroll;
}
