.navbarContainer {
  //width: max-content;
  min-width: 300px;
  //display: flex;
  position: fixed;
  height: 100vh;
  padding: 0 !important;
  margin: 0 !important;
  z-index: 999;
  background-color: var(--color-bg-variant);
  //transform: translateY(-100%);
  align-items: center;
  left: 0;
  justify-content: center;
  transition: var(--transition);
  text-align: center;
  //&:hover{
  //  transform: translateX(-100%);
  //}
}

.imgContainer {
  width: 150px;
  aspect-ratio: 1/1;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: #1f1f38;
  position: relative;


  img {
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.camera {
  position: absolute;
  bottom: 0;
  font-size: 2rem;
}

.menuToggle {
  position: absolute;
  top: 2px;
  right: -20px;
  font-size: 2rem;
  cursor: pointer;
}

.listContainer {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.navList {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  //background-color: red;
  width: 100%;

  .navLink {
    display: flex;
    gap: 1rem;
    justify-content: space-around;
    align-items: center;
    position: relative;
    color: white;
  }

  .active:after {
    content: '';
    width: 5px;
    height: 100%;
    position: absolute;
    background-color: var(--color-primary);
    right: 0;
    top: 0;
  }

}

.open.navbarContainer {
  transform: translateX(-100%);
}
